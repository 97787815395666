let clientName = "Melisana";
let productName = "PRODUCT";
/* Attention, il y a un clientName non-dynamique dans l'optin */

export const fr = {

	/* Concerning the printing coupons pages */
	printCoupons: {
		page0:{
			title: "Comment voulez-vous recevoir vos coupons ?",
			explanation: "Vous pouvez les imprimer immédiatement, ou recevoir un e-mail contenant un lien qui vous permettra de les imprimer plus tard. Quel sera votre choix ?",
			optionDirectPrint: "Les imprimer directement",
			optionMailPrint: "Recevoir un e-mail",
		}, 
		page1:{
			title: "Impression de votre bon de réduction",
			attention: "<b>Attention</b>, veuillez suivre les étapes ci-dessous afin d’imprimer votre bon de réduction.",
			step1: "Vérifiez que votre imprimante soit branchée correctement.",
			step2: "Vérifiez qu’il y ait assez de papier / encre disponible.",
			step3: "Cliquez sur le bouton «Imprimer mon bon de réduction» et votre bon de réduction sera imprimé.",
			conditions: "J’accepte les conditions générales relatives aux bons de réduction applicables à ce bon de réduction.",
			moreInfo: "Plus de détails",
			button: "Imprimer mon bon de réduction"
		}
	},


	/* pageTitles are the H2 title at the TOP of each page */
	pageTitles: {
		beforeStart: "Cette action n'est pas encore disponible.",
		actionEnded: "Cette action est terminée.",
		maxReached: "Maximum de participations atteint",
		wrongCode: "Mauvais code",
		wrongCode2: "Lien de participation incorrect",
		alreadySend: "Participation déjà envoyée",
		registration: "Inscription",
		confirmation: "Confirmation",
		choiceSurvey: "",
		survey: "Enquête",
		summary: "Récapitulatif",
		maintenance: "Maintenance",
		form1: "Demandez votre cashback maintenant\u00A0!",
		form2: "Encore quelques informations…", 
		wait: "Veuillez patienter…",
		instructions: "Comment participer",
		pageError: "Une erreur est survenue",
		catalog: "Catalogue",
	},

	/* sectionTitles are titles INSIDE pages */
	/* They should not be arranged by page, because they can move from one to another */
	sectionTitles: {
		wayOfRegistration: "Choisissez un mode d'inscription",
		thanksForRegistration: "Merci pour votre inscription\u00A0!",
		aboutYou: "Dites-nous qui vous êtes",
		address: "Votre adresse",
		aboutProduct: "À propos de notre produit",
		ticketUpload: "Preuve d'achat",
	},

	/* inputsTexts is a list for every label, placeholder and helpers that Action API don't give. */
	inputsTexts: {
		gtin : {
			label: "Tapez un code-barres",
			placeholder: "0000000000000",
			helper: "Il se compose de 13 chiffres",	
		},
		listEan : {
			label: "Sélectionnez un code-barres",
			placeholder: "Choisissez un code-barres dans la liste",
			helper: " ",
		},
		dropdown : {
			placeholder: "Choisissez une réponse…",
			helper: " ",
			missingData: "Le champ doit contenir une réponse",
			errorLength: "La longueur n'est pas bonne",
		},
		title: {
			label: "Titre",
			placeholder: "Comment pouvons-nous vous appeler ?",
			smallplaceholder: "Votre titre",
			helper: " ",
		},
		gender: {
			label: "Genre",
			placeholder: "Comment vous définissez-vous ?",
			smallplaceholder: "Comment vous définir ?",
			helper: " ",
		},
		firstname: {
			label: "Prénom",
			placeholder : "Mon prénom est…",
			helper: " ",
		},
		lastname: {
			label: "Nom",
			placeholder : "Mon nom est…",
			helper: " ",
		},
		email : {
			label: "E-mail",
			placeholder: "nom{'@'}exemple.be",
			helper: "Votre e-mail doit comporter un {'@'}"
		},
		textArea: {
			label: "Précisez-nous votre question/problématique",
			placeholder: "Je vous écris parce que…",
			helper: "Communiquez-nous des détails, comme la date et l'heure à laquelle vous avez participé/essayé de participé.",
			error_wrongValue: "Votre question ne peut pas rester vide."
		},
		refInput: {
			label: `Quel est votre numéro de dossier ?`,
			placeholder: "12345_123456…",
			helper: "Vous trouverez les références de votre dossier en bas de chaque e-mail envoyé pour les actions auxquelles vous participez.",
			error_wrongValue: "La référence doit compter 5 chiffres, un tiret puis au moins 6 chiffres : 12345_123456…"
		},
		phone : {
			label: "Mobile",
			placeholder: "Ex : 0477123456",
			helper: " ",
		},
		iban : {
			label: "IBAN",
			placeholder: "ex : BE00123000456000",
			helper: "Le numéro doit commencer par le code pays (BE/LU/…)"
		},
		VATNumber: {
			label: "Numéro de TVA",
			placeholder: "Ex: BE0123123123",
			helper: "Le numéro doit commencer par le code pays (BE/LU/…)"
		},
		googleAPI:{
			label: "Merci de rechercher votre adresse complète (avec le numéro de maison). Les champs ci-dessous se rempliront automatiquement.",
			placeholder: "Commencez par taper votre adresse ici…",
			ariaLabel: "Google adresse barre de recherche. Introduisez ici votre adresse.",
		},
		street : {
			label: "Rue",
			labelfacture: "Adresse de facturation",
			placeholder: "",
			helper: " "
		},
		houseNumber: {
			label: "Numéro",
			placeholder: "",
			helper: " "
		},
		boxNumber: {
			label: "Boite",
			placeholder: "",
			helper: " "
		},
		postalCode: {
			label: "Code postal",
			placeholder: "",
			helper: " "
		},
		postalCode2: {
			label: "Code postal de la pharmacie",
			placeholder: "",
			helper: " "
		},
		city: {
			label: "Localité",
			placeholder: "",
			helper: " "
		},
		country: {
			label: "Pays",
			placeholder: "Choisissez un pays",
			helper: " "
		},
		conditions : {
			part1 : "J'accepte les",
			link: "conditions générales",
			part3: " ",
			ariaLabel: "Case à cocher obligatoire: J'accepte les conditions générales"
		},
		serialNumber : {
			label: "",
			placeholder: "",
			helper: " "
		},
		product: {
			label: "Quel produit avez-vous acheté ?",
			placeholder: "Choisissez une réponse…",
			helper: " "
		},
		retailer: {
			label: "Où avez-vous acheté le produit ?",
			placeholder: "Choisissez une réponse…",
			helper: " "
		},
		freeRetailer: {
			label: `Dans quelle pharmacie avez-vous acheté votre produit\u00A0?`,
			placeholder: " ",
			helper: " "
		},
		freeInput: {
			label: ``,
			placeholder: "",
			helper: "",
			error_empty: "Veuillez remplir ce champ",
			error_wrongValue: "La valeur est incorrecte",
		},
		datePurchase : {
			label: `Quand avez-vous acheté vos produits ${productName}\u00A0?`,
			placeholder: "jj/mm/aaaa",
			helper: `<strong>ATTENTION :</strong> Veuillez utiliser le calendrier pour introduire la date.`,
			ariaLabel: "Choisissez une date d'achat",
		},
		quantity: {
			label: "Nombre de produits achetés",
			placeholder: "",
			helper: "&nbsp;",
		},
		uploads: {
			ticket: {
				label: "Téléchargez une photo de votre ticket",
				labeldropzone: "Cliquez ou déposez votre ticket ici.",
				ariaLabel: "Champ upload",
			},
			dropzone1: {
				label: "Téléchargez une photo du code CNK",
				labeldropzone: "Cliquez ou déposez une photo du code CNK ici.",
				ariaLabel: "Champ upload",
			},
		},
		optin : {
			part1:"Je souhaite être tenu au courant des promotions ",
			linklabel:`${clientName}`,
			link:"https://www.CLIENT.be/fr/",
			part2:"",
			label : "", /* Should not be visible */
			helper : "",
			complement: `Je souhaite être tenu au courant par e-mails des futures actions de ${clientName}.`,		
		},
		optin2 : {
			part1:"Je consens à recevoir des communications personnalisées concernant les offres, actualités et autres initiatives promotionnelles de la part de Braun et d’autres ",
			linklabel:`marques P&G`,
			link:"https://fr-be.pg.com/",
			part2:"par e-mail et sur les canaux en ligne. Je peux retirer",
			part3:"à tout moment.",
			linklabel2:`mon consentement`,
			link2:"https://preferencecenter.pg.com/fr-be/datarequests/",
			label : "", /* Should not be visible */
			helper : "",
			complement: `Je souhaite être tenu au courant par e-mails des futures actions de ${clientName}.`,
		},
		optin3 : {
			part1:"Vous avez au moins 18 ans et acceptez nos",
			linklabel:`Conditions générales.`,
			link:"https://termsandconditions.pg.com/fr-be/",
		},
	},

	testPanel: {
		title: 'Les pages "cachées"',
		text: "Cliquez sur les boutons ci-dessous pour accéder aux pages du formulaire qui ne font pas partie du flux normal de la participation",
		pages: {
			before: "Avant le début",
			after: "Après la fin",
			maxparticip: "Max. participation",
			wrongcode: "Mauvais code",
			alreadysent: "Déjà envoyé",
			maintenance: "En maintenance",
			loading: "Chargement…",
		},
		hide: "Cachez-moi",
		show: "Montrez-moi",
	},



	/* 
	ABOVE: WHAT MUST BE USED NOW
	BELOW: WHAT WAS USED BEFORE 
	*/

	page404: {
		title: "404",
		text: "Cette page n'existe pas.",
		back: "Retour au formulaire",
	},

	errors: {
		// fields errors
		emptyField: "Vous devez donner une réponse",
		dateInvalid: "La date n'est pas valide",
		dateBefore: "La date ne peut être une date future",
		dateOutOfPeriod: "L'achat doit se faire dans les dates valides pour cette action",
		wrongcnk : "Le code CNK n'est pas correct",
		fieldsMissing: "Impossible d'envoyer la participation car un ou plusieurs champs ne sont pas remplis/valides",
		fieldsMissingTitle: "Champs vides/invalides",
		unmanaged: "Une erreur imprévue est survenue.",
		// modale errors, for the user
		fieldMissingValue: "Un ou plusieurs champs ont une valeur erronée ou vide.",
		fieldMissingValueCsq: "Veuillez compléter le formulaire.",
		noDescription: "Aucune description disponible.",
		undefinedError: "Erreur indéfinie.",
		undefinedErrorPleaseRetry: "Une erreur indéfinie est survenue. Veuillez réessayer.",
		maintenanceInProgress: "Maintenance en cours.",
		formUnavailable: "Veuillez réessayer plus tard.",
		error500: "Une erreur avec le serveur est survenue.",
		// Erreurs we don't need to give user too much details on, but a ref! So the user can tell us!
		// KISS Code explanation: take the first letter of each word of the variable
		countryListUnattainable: "Code: CLU",
		noFieldConfigFound: "Code: NFCF",
		participationNotSent: "Code: PNS",
		partOwnerNotCreated: "Code: PONC",
		leadNotFound: "Code: LNF",
		retailerListNotFound: "Code: RLNF",
		productCodesNotFound: "Code: PCNF",
		localizationDataNotFound: "Code: LDNF",
		codeWrongOrUnidentified: "Code: CWOU",
		mailWithCouponsNotSent: "Code: MWCNS",
		printCouponsNotWorking: "Code: PCNW",
	},


	maintenance: {
		line1 : "Ce site web est actuellement en maintenance et ne peut pas être accédé. Réessayez plus tard.",
		line2 : "Notre équipe fait tout pour que le site soit de nouveau accessible au plus vite.",
		thanks : "Merci de votre compréhension.",
	},

	macaron: {
		big: `100%`,
		small:`remboursé`,
	},

	/* Classic Footer */
	footer: {
		genterms: "Conditions générales",
		/* Below: They appears in the footer as long as there is a link value. */
		privacy: {
			link: "",
			label: "Déclaration de confidentialité",
		},
		cookies: {
			link: "",
			label: "Politique en matière de cookies",
		}
	},

	/* Special P&G Footer */
	pgFooter: {
		conditions: "Conditions de l'action",
		/* Below: They appears in the footer as long as there is a link value. */
		privacy: {
			link: "https://privacypolicy.pg.com/fr/",
			label: "Déclaration de confidentialité",
		},
		otherconditions: {
			link:"https://www.pg.com/fr_be/terms_conditions/index.shtml",
			label:"Conditions générales"
		},
		mycoords: {
			link: "https://preferencecenter.pg.com/fr-be/",
			label: "Mes données",
		},
		contact: {
			link: "https://www.pg.com/privacy/contact_us/contact_us/privacy-central-contact-interactive.html",
			label: "Contact",
		},
	},

	gdpr: {
		title: "Avis de confidentialité",
		client: `${clientName}`,
	},

	onetrust: {
		cookie: "Gestion des cookies",
	},

	navigation: {
		doSurvey: "Répondre à l'enquête",
		skipSurvey: "Demander le cashback",
		previous: "Page précédente",
		next: "Page suivante",
		claimCashback: "Demander mon cashback",
		signup: "M'inscrire\u00A0!",
		participate: "Participer\u00A0!",
		toCustomerWebsite: {
			label: "Vers notre site",
			link: ""
		},
		readMoreOnWebsite: "En savoir plus",
	},

	breadcrumbSteps: {
		info: "Comment Participer",
		registration: "Enregistrez-vous",
		store: "Faites vos achats",
		form: "Remplissez le formulaire",
		survey: "Répondez à un questionnaire optionnel",
		cashback: "Recevez votre remboursement",
		summary: "Récapitulatif"
	},

	formHeader: {
		bottomText: `* à la conditions…`,
		macaronText: `remboursés\u00A0!`,
		mainTitle: "Inscrivez-vous et obtenez votre cashback\u00A0!",
		altText: "banner"
	},



	pageLitteracy: {
		pageError: {
			intro: "Une erreur est survenue qui empêche le formulaire de fonctionner.",
			solution1 : "Veuillez essayer de recharger le formulaire ou réessayer plus tard.",
			solution2 : `Si l'erreur persiste, prenez contact avec Promolife sur le mail <a href="mailto:infoligne{'@'}promolife.be">infoligne{'@'}promolife.be</a> ou au <a href="tel:3223520398">+32 2 352 03 98</a>`,
		},
		instructions : {
			intro: "Instructions de participation comme dans une HAv1. Pas de breadcrumb ici, sinon double emploi (icones, textes).",
			steps: {
				info : "Page d'information",
				register: "Si vous comptez participer plus tard, inscrivez-vous maintenant, ainsi, vous recevrez un rappel.",
				store: {
					line1a: "Rendez-vous en magasin entre le",
					line1b: "et le",
					line2: "Achetez un produit MARQUEMACHIN.",
					line3: "Conservez bien votre ticket de caisse / facture.",
				},
				form: {
					line1: "Introduisez vos coordonnées complètes.",
					line2: "Sur la preuve d’achat de vos produits MARQUEMACHIN (facture / ticket de caisse), entourez la date, les produits achetés et le prix.",
					line3: "Photographiez ou scannez ensuite votre preuve d’achat dans son entièreté.",
					line4: "Téléchargez cette photo ou ce scan.",
				},
				survey: "Répondez à une enquête optionnelle et recevez un coupon de réduction.",
				cashback: "C’est terminé. Après validation, vous recevrez votre remboursement dans un délai de 6 semaines.",
			}
		},
		maxReached : "Cette action est terminée car elle a atteint son maximum de participations.",
		wrongCode: {
			text: `La clé fournie est erronée. Vous devez utiliser le lien que vous a envoyé ${clientName}.`,
			textregistration: "Mais peut-être souhaitez-vous vous inscrire à l'action\u00A0?",
			errorWithReparticipation: `Une erreur est survenue avec votre lien de participation.<br>Veuillez contacter nos équipes techniques en envoyant un email à <a href="mailto:infoligne{'@'}promolife.be">infoligne{'@'}promolife.be</a> en joignant les informations ci-dessous&nbsp;:`
		},
		alreadySend: "Vous avez déjà envoyé votre participation.",
		registration : {
			byRegistering: "En m'inscrivant à cette action, j'accepte ",
			privacyNotice: "la politique en matière de vie privée",
			claimMail : "S'inscrire par e-mail",
			claimWhatsapp: "S'inscrire par Whatsapp",
			claimSms: "S'inscrire par SMS",
			or: "Ou",
			whatsappTitle: "Cliquez sur le bouton Whatsapp",
			whatsappInfo: {
				part1: `Une fenêtre s'ouvrira, vous serez ensuite invité à envoyer le mot`,
				part2: `depuis votre Whatsapp.`
			},
		},
		confirmRegistration: {
			smsLine1 :"Vous allez très vite recevoir un SMS avec tous les détails.",
			smsLine2: "Aller voir !",
			whatsappLine1: "Vérifiez votre téléphone",
			whatsappLine2: "Toutes les informations ont été envoyées par Whatsapp",
			mailLine1: "Vous allez très vite recevoir un e-mail avec tous les détails.",
			mailLine2: "Découvrez cet e-mail\u00A0!",
		},
		form1: {
			legal: ``,
		},
		choiceSurvey: {
			title:'',
			fillSurveyIncite: "Répondez encore à une enquête et obtenez un e-coupon de 1€ valable sur votre prochain achat",
		},
		survey: {
			title: "Merci d'encore répondre à ces quelques questions",
			about: "Enquête",
		},
		confirmation: {
			title: "Confirmation",
			textes: {
				p1: `Nous vous remercions pour votre participation à l'action.`,
				p2: "Vous recevrez votre remboursement endéans les 6 semaines pour autant que vous ayez rempli les conditions générales.",
				coupon: "Nous avons envoyé votre e-coupon à l'adresse e-mail indiquée. Profitez-en\u00A0!",
				p3: "Bien à vous.",
				p4: ``
			},
		},
		confirmationCoupon: {
			title: "Confirmation",
			textes: {
				p1: `Nous vous remercions d'avoir imprimé vos eCoupons.`,
				p2: "Foncez dans votre magasins de plus proche afin de les échanger.",
				coupon: "Nous avons envoyé vos eCoupons à l'adresse e-mail indiquée. Profitez-en bien\u00A0!",
				p3: "",
				p4: `L'équipe ${clientName}`
			},
		},
		confirmationMail: {
			title: "L'e-mail a bien été envoyé",
			textes: {
				p1: `L'e-mail a bien été envoyé. Vous devriez le recevoir bientôt. Si ce n'est pas rapidement le cas, pensez à vérifier s'il n'a pas été placé dans les courriers indésirables.`,
			}
		},
		actionEnded: {
			text: "N'hésitez pas à visiter régulièrement notre site pour vous tenir informé des actions futures."
		},
		beforeStart: {
			text: "Vous pourrez y accéder à partir du ",
			shouldBeginSoon: "L'action devrait bientôt commencer. Veuillez revenir plus tard."
		},
	},


	popUp: {
		title: "Informations publicitaires pertinentes et publicités en ligne personnalisées",
		text: "Nous avons à cœur de rendre votre expérience chez nous et auprès des marques du groupe P&G intéressante, plaisante et personnalisée. Pour cela, nous nous efforçons d’élaborer nos communications commerciales en fonction de vos centres d’intérêt et évitons de vous envoyer des propositions qui ne correspondent pas à vos goûts ou de vous diffuser des publicités que vous avez déjà vues plusieurs fois. Comment procédons-nous ?",
		ul:{
			li1:"Regroupement des données pour mieux personnaliser nos communications en fonction de vos centres d’intérêt. En nous fondant sur notre intérêt légitime, nous sommes susceptibles d’enrichir vos données d’inscription et les données que vous nous divulguez lorsque vous utilisez nos services (marques préférées, bons de réductions épinglés, etc.) avec des attributs, des centres d’intérêt, des données démographiques obtenues auprès de sources commercialement disponibles ou de tiers. De même, si vous avez accepté nos cookies ou technologies similaires concernant les publicités ciblées sur les sites Web ou applications de P&G ou, dans certains cas, sur les sites Web ou applications de tiers, nous sommes susceptibles de compléter les données obtenues grâce à nos cookies relatifs aux publicités ciblées avec d’autres informations que vous nous avez communiquées au moment de la création d’un compte ou de l’utilisation de nos services.",
			li2:"Nous traiterons ces données, notamment par des moyens automatisés, pour personnaliser notre contenu et nos offres envoyés par e-mail, en ligne (par exemple, sur des plateformes de tiers, y compris les plateformes de réseaux sociaux) et hors ligne (par exemple, par courrier postal) si vous avez consenti à recevoir des communications commerciales de notre part.",
			li3:"Ciblage d’audiences similaires. Nous nous fondons sur notre intérêt légitime pour analyser vos données et identifier de nouveaux individus au profil similaire au vôtre et qui pourraient être intéressés par notre marque. Par exemple, nos sociétés affiliées P&G et nous sommes susceptibles d’envoyer votre adresse e-mail hachée sur Facebook et de demander à Facebook de proposer nos publicités à des individus dont les centres d’intérêt sont similaires aux vôtres, y compris dans d’autres pays, sur la base de données que Facebook détient à votre sujet et au sujet d’autres individus.",
			li4:"Utilisation de vos données à caractère personnel pour vous contacter sur des médias en ligne. Si vous avez consenti à la réception de communications commerciales de notre part, nous utiliserons vos données à caractère personnel (c’est-à-dire, votre adresse e-mail, votre numéro de téléphone, ou votre ID publicitaire mobile) hachées pour vous envoyer des publicités sur des canaux en ligne (par exemple, sur votre compte de réseau social). Pour cela, nous passons par un processus qui respecte la confidentialité de vos données en utilisant des données chiffrées et nous faisons appel à des partenaires de choix. Plus particulièrement, nous partagerons vos données à caractère personnel avec des tiers qui nous proposent des services de publicités personnalisées, y compris des plateformes de réseaux sociaux. Nous avons recours à ce genre de services pour associer les informations hachées que nous détenons avec les données à caractère personnel contenues dans leurs bases de données pour créer des audiences personnalisées et adapter les publicités à vos centres d’intérêt sur Internet, y compris les réseaux sociaux, comme l’autorise la loi.",
			li5:"Cela nous permet de vous envoyer des publicités en ligne plus pertinentes (offres, informations sur les produits et innovations) et de vous envoyer moins de publicités, étant donné que nous savons quand nous vous avons déjà envoyé une publicité et que nous pouvons vous exclure des communications suivantes sur le même sujet. Nos clients apprécient généralement que nous ne leur partagions pas de publicités sur des produits qui ne les intéressent pas ou qu’ils ont déjà vues plusieurs fois, mais si ce n’est pas le cas, vous pouvez toujours modifier vos préférences.",
			li6:"Vous pouvez toujours changer d’avis et retirer votre consentement/refuser l’utilisation de vos données pour l’envoi de communications et publicités personnalisées, y compris la création d’audiences personnalisées et similaires, ",
			li6Link: "https://preferencecenter.pg.com/fr-be/datarequests/",
			li6LinkLabel: "ici",
			li6Part2: ".",
			li6Link2: "https://privacypolicy.pg.com/fr-BE/",
			li6LinkLabel2: "Lire notre politique de confidentialité.",
		}
	},

	popUp2: {
		title: "Votre confidentialité nous importe",
		ul:{
			li1:"Notre politique de confidentialité régit notre collecte et notre utilisation de vos données",
			li2:"C'est",
			li2Link: "https://privacypolicy.pg.com/fr-BE/",
			li2LinkLabel:"pourquoi",
			li2Part2:"nous collectons vos données, nos bases juridiques pour le traitement, et la période pendant laquelle vos données personnelles seront conservées.",
			li3:"Nos e-mails contiennent des pixels qui aident P&G à comprendre si vous interagissez avec eux et quel contenu vous intéresse. En savoir plus",
			li3Link:"https://privacypolicy.pg.com/fr-BE/",
			li3LinkLabel:"ici",
			li3Link2:"https://preferencecenter.pg.com/fr-be/datarequests/",
			li3LinkLabel2:"désinscrire",
			li3Part2:". Vous pouvez vous",
			li3Part3:"de nos programmes de communication par e-mail à tout moment.",
			li4:"Vos informations personnelles seront traitées vers un pays différent de celui où elles ont été recueillies, notamment aux États-Unis. En savoir plus",
			li4Link:"https://privacypolicy.pg.com/fr-BE/",
			li4LinkLabel:"ici",
			li4Part2:".",
			li5:"Vous pouvez exercer vos droits de détenteur de données (vous pouvez accéder, corriger, effacer, transférer à un autre prestataire, refuser ou restreindre notre traitement de vos données personnelles, ou retirer votre consentement) ou changer vos préférences et abonnements en cliquant",
			li5Link:"https://preferencecenter.pg.com/fr-be/datarequests/",
			li5LinkLabel:"ici",
			li5Part2:".",
		}
	},

	PGLegal: {
		title: "Pourquoi collectons-nous ces données ?",
		mainText:{
			personalData: "Procter&Gamble, le responsable de traitement, traitera vos données à caractère personnel pour vous permettre de vous inscrire sur le présent site et d’utiliser ses services, et, si vous y consentez, pour vous envoyer des communications commerciales, y compris des publicités personnalisées sur les médias en ligne.",
			moreInfoLinkLabel: "En savoir plus",
			privacyInfoText: "Pour de plus amples informations concernant le traitement de vos données et vos droits en matière de confidentialité, veuillez vous reporter à",
			privacyLinkLabel: "cette section",
			privacyInfoText2: "ou consulter notre",
			privacyPolicyLinkLabel: "Politique de confidentialité",
			privacyLink: "https://privacypolicy.pg.com/fr-BE/",
			privacyInfoText3: " intégrale.",
		}
	},

	counterText: {
		participationLeft : {
			before: "Encore",
			after: "participations disponibles",
		},
		daysLeft: {
			before: "Il vous reste",
			after: "jours pour participer !"
		}
	},




	inputLabel: {
		defaultListHelper: "Choisissez une proposition dans la liste",
		mandatoryFields: "Champs obligatoires",
		noResult: "Pas de résultat",





		empty : {
			label: "",
			placeholder: "",
			helper: ""
		},
		cnk1 : {
			label: "Quel est le code CNK du produit Clearblue acheté\u00A0?",
			placeholder: "0000-000",
			helper: "Le format est : XXXX-XXX",
			error: "Le code CNK n'est pas correct",
		},



        // https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation



/*
        typePassword : "Indiquez votre mot de passe",
        helpPassword : "Votre mot de passe doit contenir X, Y et Z",
        typeUniqueCode : "Indiquez ici le code visible sur le ticket de caisse",
        */


		upload: {
			state: "statut",
			uploadYourFile: "Envoyer vos fichiers",
			clickOrDrop: "Cliquez ou déposez votre ticket ici.",
			rules: "L'image doit être un fichier de type : JPG, JPEG, PNG, BMP, GIF, SVG, WEBP, PDF",
			remove: "Retirer",
			waiting: "En attente de l'envoi…",
			uploaded: "Envoyé !",
			error: "Echoué",
			uploadsDone: "Upload réussi",
			onlyImages: "Seulement des images sont acceptées.",
			maxSize: "Taille maximum de 20 Mo.",
			uploadFile: "Télécharger le fichier",
			generalError: "Erreur, veuillez ressayer plus tard"
		}
	},
}
